import React, { Component } from "react";
import { func } from "prop-types";
import { navigate } from "gatsby";
import { connect } from "react-redux";
import { Button } from "@instructure/ui-buttons";
import IconArrowOpenEnd from "@instructure/ui-icons/lib/IconArrowOpenEndSolid";
import IconArrowOpenStart from "@instructure/ui-icons/lib/IconArrowOpenStartSolid";

import Layout from "../../../components/layout";
import SEO from "../../../components/seo";
import styles from "./summary.module.css";
import {
  setCurrentModule,
  setCurrentStep,
  completeModule
} from "../../../state/actions";

export class Summary extends Component {
  static propTypes = {
    handleNavigate: func,
    goToStep: func,
    completeModule: func
  };

  componentDidMount() {
    // Make sure that the progress bar always shows
    this.props.handleNavigate(1);
    this.props.goToStep(7);
  }

  state = {
    completed: false
  };

  onSubmit = () => {
    if (!this.state.completed) {
      this.setState({ completed: true });
    } else {
      this.props.completeModule();
    }
  };

  goBack = () => {
    if (this.state.completed) {
      this.setState({ completed: false });
    } else {
      this.props.goToStep(6, "/modules/normalcy/final-scenario");
    }
  };

  render() {
    return (
      <Layout isModule>
        <SEO
          title="Module - Normalcy - Reasonable & Prudent Parenting Standards"
          keywords={["utah foster care", "foster care"]}
        />
        <div className={styles.moduleGridContainer}>
          {this.state.completed ? (
            <p className={styles.openingParagraph}>
              <b>Congratulations!</b> You have completed the module on Normalcy.
              Feel free to refer back to this information at any time.
              <br /> <br />
              <b>
                Use the next button to mark this module as completed and return
                to the training home page.
              </b>
            </p>
          ) : (
            <p className={styles.openingParagraph}>
              <b>Let's Summarize</b>
              <br /> <br />
              <ul className={styles.bullettedList}>
                <li>A child can participate in normal kid activities.</li>
                <li>
                  The foster parent decides if an activity is appropriate and
                  gives permission.{" "}
                </li>
                <li>
                  Use the reasonable and prudent parenting standard to decide
                  (ARBEFI):
                  <ul>
                    <li>Age/Maturity</li>
                    <li>Risk</li>
                    <li>Behavioral History</li>
                    <li>Emotional Development</li>
                    <li>Family-like Scenario</li>
                    <li>Best Interest of Child</li>
                  </ul>
                </li>
                <li>
                  Discuss activity with caseworker:
                  <ul>
                    <li>If unsure about your decision </li>
                    <li>If activity is higher-risk </li>
                  </ul>
                </li>
                <li>
                  For high-risk activities:
                  <ul>
                    <li>Get caseworker approval</li>
                    <li>Ensure child follows safety rules/protocol</li>
                  </ul>
                </li>
              </ul>
            </p>
          )}

          <div className={styles.backButtonArea}>
            <Button
              onClick={this.goBack}
              variant="success"
              icon={IconArrowOpenStart}
            />
          </div>
          <div className={styles.forwardButtonArea}>
            <Button
              onClick={this.onSubmit}
              variant="success"
              icon={IconArrowOpenEnd}
            />
          </div>
        </div>
      </Layout>
    );
  }
}

const stateToProps = (state, ownProps) => ({
  ...ownProps
});

const dispatchToProps = dispatch => {
  return {
    handleNavigate(moduleId) {
      dispatch(setCurrentModule(moduleId));
    },
    goToStep(stepId, navigateTo) {
      dispatch(setCurrentStep(stepId));
      if (navigateTo) {
        navigate(navigateTo);
      }
    },
    completeModule() {
      dispatch(completeModule(1));
      navigate("/");
    }
  };
};

export default connect(
  stateToProps,
  dispatchToProps
)(Summary);
